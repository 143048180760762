export function getValidationStatus(valueOrArgs) {
    if (typeof valueOrArgs === 'boolean' || valueOrArgs === undefined) {
        // Handle the boolean or undefined case
        if (valueOrArgs === false)
            return 'error';
        if (valueOrArgs === true)
            return 'success';
        return undefined;
    }
    // Handle the error/success/highlight object case
    if (valueOrArgs.error === true)
        return 'error';
    if (valueOrArgs.success === true)
        return 'success';
    if (valueOrArgs.highlight === true)
        return 'highlight';
    return undefined;
}
